import Mixpanel from 'mixpanel-browser';
import config from '../config/config';

const moment = require('moment');

Date.prototype.toShortFormat = function () { // eslint-disable-line
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr',
    'May', 'Jun', 'Jul', 'Aug',
    'Sep', 'Oct', 'Nov', 'Dec'];

  const day = this.getDate();

  const monthIndex = this.getMonth();
  const monthName = monthNames[monthIndex];

  const year = this.getFullYear();

  return `${day} ${monthName} ${year}`;
};

const secondsToFormattedString = (seconds) => {
  const second = seconds % 60;
  const min = Math.floor(seconds / 60);
  const secondString = second.toString();
  const minString = min.toString();

  const formattedSecondString = secondString.length > 1 ? secondString : `0${secondString}`;
  const formattedMinString = minString.length > 1 ? minString : `0${minString}`;

  return `${formattedMinString}:${formattedSecondString}`;
};

const randomlySelectAnElementFromArray = array => array[Math.floor(Math.random() * array.length)];

const formInterviewQuestions = questions => questions.reduce((list, question) => {
  const selectRandomQuestion = () => {
    const selectedQuestion = randomlySelectAnElementFromArray(question.questions);
    const isDuplicateQuestion = list.find(q => q.questionid === selectedQuestion.questionid);
    if (isDuplicateQuestion) { // check for duplicate question
      return selectRandomQuestion();
    }
    return { ...selectedQuestion, timeInSec: question.timeInSec, index: question.index };
  };

  if (question.type === 'random') {
    return [...list, selectRandomQuestion()];
  }
  return [...list, question];
}, []);

const secondsToMinAndSecondString = (seconds, t) => {
  const second = seconds % 60;
  const min = Math.floor(seconds / 60);
  const secondString = second === 0 ? '' : t('secondWithCount', { count: second });
  const minString = min === 0 ? '' : t('minuteWithCount', { count: min });

  return `${minString} ${secondString}`;
};

const getTotalSecondsFromQuestionsTime = questions => (
  questions.reduce((acc, currValue) => (
    acc + parseInt(currValue.timeInSec, 10)
  ), 0)
);

const getFileExtension = filename => (
  (filename.lastIndexOf('.') !== -1) ? filename.substring(filename.lastIndexOf('.') + 1) : ''
);

const mxTrackEvent = (eventName, eventProperty) => Mixpanel.track(eventName, eventProperty);

const ipify = () => (
  new Promise(async (resolve, reject) => {
    try {
      const req = await fetch('https://api.ipify.org');
      if (req.ok) {
        const ip = await req.text();
        return resolve(ip);
      }
      return reject(new Error('Error requesting ipify'));
    } catch (e) {
      return reject(e);
    }
  })
);

const myip = () => (
  new Promise(async (resolve, reject) => {
    try {
      const req = await fetch('https://ipapi.co/json/');
      if (req.ok) {
        const resp = await req.json();
        return resolve(resp);
      }
      return reject(new Error('Error requesting myip'));
    } catch (e) {
      return reject(e);
    }
  })
);

const isInsightProInterview = interviewId => (
  interviewId === config.default.INSIGHT_PRO_INTERVIEW
);

const getUrlParams = (search) => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  const params = {};

  hashes.map((hash) => {
    const [key, val] = hash.split('=');
    if (key && val) {
      params[key] = decodeURIComponent(val);
    }
    return params;
  });

  return params;
};

const cleanDictionaryValue = (dict) => {
  Object.keys(dict).map((key) => {  //eslint-disable-line
    dict[key] = dict[key].trim(); // eslint-disable-line no-param-reassign
    return dict[key];
  });

  return dict;
};

const generateRandomCharacters = () => Math.random().toString(36).substr(2, 6);

const getFullError = (errMessage, duplicateDetail) => {
  if (errMessage.includes('{}')) {
    return errMessage.replace(/{}/g, `using ${duplicateDetail}`);
  }
  return errMessage;
};

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // eslint-disable-line no-param-reassign
  }
  return array;
};

const getRedirectURL = (source, data) => {
  const { POST_INTERVIEW_REDIRECTS } = config.default;
  const url = POST_INTERVIEW_REDIRECTS[source];

  if (url && data) {
    if (source === 'Search4Maids API') {
      // TODO: Move SUCCESS_CODE out to construct the URL according to the interview status
      const SUCCESS_CODE = 0;
      return `${url}?code=${SUCCESS_CODE}&candidate_id=${data.s4m_candidate_id}`;
    }
  }

  return null;
};

const getAverageScore = (scores) => {
  const attributes = Object.values(scores).filter(a => a !== undefined);
  let totalScore = 0;
  if (attributes.length > 0) {
    totalScore = attributes.reduce((total, currentScore) => {
      if (currentScore !== undefined) {
        return total + currentScore;
      }
      return total;
    }, 0);
    totalScore /= attributes.length;
  }

  return {
    attributes: attributes.length,
    totalScore,
  };
};

const capitaliseWord = word => (
  word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
);

const isObjectEmpty = data => (
  data
  && Object.keys(data).length === 0
  && Object.getPrototypeOf(data) === Object.prototype
);

const dateFormatDdMmmYyyy = (date) => {
  if (date) {
    const dateObject = new Date(date);
    return dateObject.toShortFormat();
  }
  return '-';
};

const getGreetings = () => {
  const today = new Date();
  const currentHour = today.getHours();

  if (currentHour < 12) {
    return 'Good Morning';
  } if (currentHour < 18) {
    return 'Good Afternoon';
  }
  return 'Good Evening';
};

const currentCountryUsingIp = async () => {
  try {
    const ip = await myip();
    const { country_name: countyrName } = ip;
    return countyrName.toLowerCase();
  } catch {
    console.log('Error fetching candidate location default location set to USA');
    return 'USA';
  }
};

const getDaysDiff = date => Math.ceil(moment(date).diff(moment(), 'days', true));

const getSubscriptionIsLocked = async (expiryDate) => {
  if (!expiryDate) {
    return false;
  }
  const daysRemaining = getDaysDiff(expiryDate);
  if (daysRemaining <= 0) { // lock account on EXPIRY DATE
    return true;
  }
  return false;
};

const capitalizeFirstLetter = value => value.charAt(0).toUpperCase() + value.slice(1);

const getTotalBill = (pricing, billingCycle, percentOff = null, amountOff = null) => {
  const totalPrice = pricing;
  if (percentOff) {
    return Math.round(totalPrice * (1 - percentOff / 100));
  }

  if (amountOff) {
    return Math.round(totalPrice - amountOff / 100);
  }

  return Math.round(totalPrice);
};

const getDiscountAmount = (pricing, finalValue) => {
  const totalPrice = pricing;
  return totalPrice - finalValue;
};

const checkInterviewDate = (startDay) => {
  const startDate = new Date(startDay);
  const currentDate = new Date();

  if (startDate > currentDate) {
    return false;
  }
  return true;
};

const isMobile = () => {
  const mediaQuery = window.matchMedia('(max-width: 767px)');
  return mediaQuery.matches;
};

const fetchScoreBreakDowns = (score, scoreParameter) => {
  const professionalismScoreBreakDown = ['dressingScore', 'hairTidinessScore', 'bodyLanguageScore'];
  const sociabilityScoreBreakDown = ['emotionScore', 'arousalScore', 'sentimentScore'];
  const communicationBreakDown = ['communicationScore', 'articulationScore', 'sentimentScore'];
  const positiveAttitudeBreakDown = ['arousalScore'];

  const obj = [];
  if (scoreParameter === 'professionalism') {
    professionalismScoreBreakDown.forEach((x) => {
      obj.push({
        [x]: score[x],
      });
    });
  } else if (scoreParameter === 'sociability') {
    sociabilityScoreBreakDown.forEach((x) => {
      obj.push({
        [x]: score[x],
      });
    });
  } else if (scoreParameter === 'communication') {
    communicationBreakDown.forEach((x) => {
      obj.push({
        [x]: score[x],
      });
    });
  } else if (scoreParameter === 'energyLevels') {
    positiveAttitudeBreakDown.forEach((x) => {
      obj.push({
        [x]: score[x],
      });
    });
  }
  return obj;
};

const getProgressColorClass = (score, max = 100) => {
  let scoreNum = score;
  scoreNum = (scoreNum * 100) / max;

  if (scoreNum >= 60) {
    return 'success';
  }

  if (scoreNum >= 40 && scoreNum < 60) {
    return 'warning';
  }

  return 'danger';
};


const removeSpacesAndCamelCase = (str) => {
  const words = str.split(/[-_\s]/);
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return camelCaseWords.join('');
};

const mxUserProfile = (
  email,
) => { // eslint-disable-line
  console.log('created user profile with email....', email);
  Mixpanel.identify(email);
  Mixpanel.people.set({
    '$email': email, // eslint-disable-line
  });
};

export {
  secondsToFormattedString,
  secondsToMinAndSecondString,
  getTotalSecondsFromQuestionsTime,
  getFileExtension,
  mxTrackEvent,
  ipify,
  myip,
  isInsightProInterview,
  getUrlParams,
  cleanDictionaryValue,
  randomlySelectAnElementFromArray,
  formInterviewQuestions,
  generateRandomCharacters,
  getFullError,
  shuffleArray,
  getRedirectURL,
  getAverageScore,
  capitaliseWord,
  isObjectEmpty,
  dateFormatDdMmmYyyy,
  getGreetings,
  currentCountryUsingIp,
  getDaysDiff,
  getSubscriptionIsLocked,
  capitalizeFirstLetter,
  getTotalBill,
  getDiscountAmount,
  checkInterviewDate,
  isMobile,
  fetchScoreBreakDowns,
  getProgressColorClass,
  removeSpacesAndCamelCase,
  mxUserProfile,
};
