const INTERVIEW_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  GET_INTERVIEW_FAILURE: 'GET_INTERVIEW_FAILURE',
  STARTED: 'STARTED',
  ENDED: 'ENDED',
  SUBMITTED_FEEDBACK: 'SUBMITTED_FEEDBACK',
  SUBMITTING: 'SUBMITTING',
  SUBMITTED: 'SUBMITTED',
  SUBMIT_FAILURE: 'SUBMIT_FAILURE',
  TEST_QUESTION: 'TEST_QUESTION',
  COMPLETED: 'COMPLETED',
  TEST_QUESTION_REVIEW: 'TEST_QUESTION_REVIEW',
  MULTIPLE_ATTEMPT_NOT_ALLOWED: 'MULTIPLE_ATTEMPTS',
  PROFILE_INPUT: 'PROFILE_INPUT',
  CASE_STUDY_DESCRIPTION: 'CASE_STUDY_DESCRIPTION',
  WORKMAP_REQUIRED: 'WORKMAP_REQUIRED',
  INTERVIEW_EXPIRED: 'INTERVIEW_EXPIRED',
  MOBILE_IMPORTANT_NOTES: 'MOBILE_IMPORTANT_NOTES',
  DOMAIN_QUESTION_REQUIRED: 'DOMAIN_QUESTION_REQUIRED',
  QUIT_FEEDBACK_SCREEN: 'QUIT_FEEDBACK_SCREEN',
};

const RECORDING_STATUS = {
  READING_QUESTION: 'READING_QUESTION',
  COUNTING_DOWN: 'COUNTING_DOWN',
  RECORDING: 'RECORDING',
};

const APPLICATION_STATUS = {
  APPLYING: 'APPLYING',
  SUCCESS: 'SUCCESS',
  SUCCESS_DELAY_INTERVIEW: 'SUCCESS_DELAY_INTERVIEW',
  SUCCESS_PENDING_REVIEW: 'SUCCESS_PENDING_REVIEW',
};

const HOSPITALITY_ROLES = [
  'Admin Executive',
  'Event Planner',
  'Barista',
  'Event Crew (Part Time)',
  'Bartender',
  'F&B Host/Hostess',
  'Bellman',
  'Kitchen Crew',
  'Concierge',
  'Maintenance Technician',
  'Chef',
  'Room/Housekeeping Attendant',
  'Customer Service Officer',
  'Sales Executive',
];

const CLOSING_ACCOUNT_RESPONSES = [
  'I have a duplicate account',
  'I\'m getting too many emails',
  'I\'m not getting any value from my membership',
  'I have a privacy concern',
  'I had issues accessing my account',
  'Other',
];

const ERROR_CODES = {
  DEFAULT: { code: 'DEFAULT', message: 'There is some error in the application :( Please try again later' },
  REC1001: { code: 'REC1001', message: 'You have already applied to this job {}.' },
  REC1002: { code: 'REC1002', message: 'You have already been invited to this job.' },
  REC1003: { code: 'REC1003', message: 'You have already withdrawn application from this interview.' },

};

const AUTH_REDIRECT_KEY = 'AUTH_REDIRECT';
const LANGUAGE_KEY = 'LANGUAGE';
const META_TITLE_COMPANY_HIRING = 'Jobs | Interviewer.AI';
const META_DESCRIPTION_COMPANY_HIRING = 'Explore career opportunities, find new jobs and get started. Find open roles and get started today!';

const SUPPORTED_LANGUAGES = ['en-US', 'pl', 'es-ES', 'id-ID', 'zh-CN', 'fr-FR', 'vi-VN', 'de-DE'];

const PURCHASE_AMOUNT_US = '99';
const PURCHASE_AMOUNT_INDIA = '5999';
const STRIKE_THROUGH_VALUE = '149.99';

const BLURED_SCORE_IMAGE = 'https://s3.ap-southeast-1.amazonaws.com/interviewer.ai/candidate+overview+report+placeholder.png';
const DUMMY_REPORT_IMAGE = 'https://interviewer-ai-assets.s3.ap-southeast-1.amazonaws.com/dummyReport.jpg';
const BANNER_PERSON_IMAGE = 'https://s3.ap-southeast-1.amazonaws.com/interviewer.ai/candidate_banner.png';
const CANDIDATE_OVERVIEW_BANNER_USD = 'https://s3.ap-southeast-1.amazonaws.com/interviewer.ai/candidate+purchase+banner+usd.png';
const CANDIDATE_OVERVIEW_BANNER_INR = 'https://s3.ap-southeast-1.amazonaws.com/interviewer.ai/candidate+purchase+banner+inr.png';
const CANDIDATE_PURCHASE_BANNER_INR = 'https://s3.ap-southeast-1.amazonaws.com/interviewer.ai/candidate+detail+purchase+banner+inr.png';
const CANDIDATE_PURCHASE_BANNER_USD = 'https://s3.ap-southeast-1.amazonaws.com/interviewer.ai/candidate+detail+purchase+banner+usd.png';
const CANDIDATE_MOBILE_PURCHASE_BANNER_USD = 'https://interviewer-ai-assets.s3.ap-southeast-1.amazonaws.com/candidate+mobile+usd+banner.png';
const CANDIDATE_MOBILE_PURCHASE_BANNER_INR = 'https://interviewer-ai-assets.s3.ap-southeast-1.amazonaws.com/candidate+mobile+INR+banner.png';


/* eslint-disable */
const WORKMAP_DEFINITIONS = {
  'Active Listening': 'Making conscious efforts to listen to what others are saying attentively without interruption and asking questions when appropriate.',
  'Adaptable': 'Maintaining work effectiveness when experiencing major changes in work tasks or work environment by swiftly adjusting to new work structures, processes, requirements, or cultures. ',
  'Analytical': ' Visualising, articulating, conceptualising or solving complex problems by making decisions that are sensible with available information and resources. ',
  'Attention to Detail ': 'Completing work tasks with thoroughness and accuracy through having concern for all areas involved.',
  'Assertive': 'Confidently communicate opinions and ideas respectfully without coming off as disingenuous or pushy. ',
  'Accountability': 'Accepts full responsibility and ownership of one’s thoughts, behaviours, actions and performance as a team member; displaying a strong commitment towards organisational success and inspiring others to commit to goals.',
  'Big Picture Thinking': 'aking on a creative and visionary approach to new ideas, solutions and opportunities. ',
  'Business Acumen': 'Having a deep and applicable understanding of how a company operates and achieves its goals and objectives to make optimal business decisions. ',
  'Business Development': 'Executing strategic ideas, initiatives and activities aimed towards improving business in terms of revenues and profitability.',
  'Business Process Improvement': 'Identifying, planning and implementing key projects to improve quality, reduce cost, increase productivity and improve cycle time to significantly improve business processes and boost customer satisfaction. ',
  'Change Management': 'Implementing strategies and methods to help people accept and deal with transitions or transformations that are associated with change. ',
  'Closing Techniques': 'Skillfully select and execute various closing techniques by means of artful persuasion to close a deal with customers. ',
  'Commercial Acumen': 'Maintains and applies a broad understanding of financial management principles to the financial goals of the company and ensures that decisions are fiscally sound and responsible. ',
  'Communication': 'Conveying or sharing information, thoughts and ideas effectively using various approaches and methods effectively. ',
  'Computational Thinking': 'Using computational tools, algorithms and techniques to understand and develop solutions that guide decision making. ',
  'Conflict Resolution': 'Participate or facilitate the process by which two or more parties reach a peaceful resolution to a dispute. ',
  'Coordination': 'Organising and integrating the efforts and plans of two or more departments to synchronize actions towards a common goal. ',
  'Creative Thinking': 'Adopting fresh ideas and perspectives by combining ideas and information in new ways that present a connection between two seemingly unrelated fields to create new applications and ideas. ',
  'Coaching': 'Providing guidance and feedback to help an employee or a group of employees enhance their knowledge and skills to accomplish a task or solve a problem, which in turn improves job performance. ',
  'Critical Thinking': 'Analysing information in an organized, rational and factual manner to reveal connections or generate insights that shape informed decisions. ',
  'Customer Service': 'Displaying the traits and practices required to foster a positive customer experience.',
  'Data Management ': 'Organising, storing and maintaining data effectively to conduct insightful analyses that help drive strategic planning and business decisions. ',
  'Dedicated': 'Displaying self-disciplined behaviours at work to persist on job tasks; volunteering for additional work beyond the tasks outlined in their job description. ',
  'Developing People ': 'Helping others develop their capabilities and competencies by rendering advice from personal experience and expertise. ',
  'Dependable': 'Reliable and dependable in performing job-related tasks, finishing assigned projects, meeting deadlines and appointments. ',
  'Digital Literacy': 'Using information and communication technology tools to assess, create, and communicate information with others. ',
  'Decision Making': 'Considering the relative costs and benefits of potential actions and choosing the most appropriate one to achieve intended goals. ',
  'Diversity Management ': 'Remain accommodating and flexible to work with people from different ethnic, social, cultural and educational backgrounds by understanding their concerns and interests.',
  'Emotional Intelligence': 'Navigate social situations by understanding how emotions, thoughts and beliefs may affect personal behaviour. ',
  'Global Mindset': 'Awareness of diversity in traditions and cultural norms from around the world and seeking to make an impact in all environments.',
  'Independent': 'Being able to work with little to no supervision or without others in a team setting; relying on oneself to problem solve and make decisions. ',
  'Influencing': 'Bringing people round to a certain way of thinking about a topic without being coercive, whilst acknowledging their opinions. ',
  'Initiative': 'Taking proactive and necessary action on one’s own accord whenever the need arises.',
  'Insightful Questioning': ' Formulating questions to direct the search for further information and conceptualise information that has been spoken about in a conversation.',
  'Instructing': 'Possessing the relevant knowledge and techniques to teach others how to do something. ',
  'Interpersonal Skills ': 'Managing relationships efficiently and effectively communicate with others to achieve mutual consensus and outcomes.',
  'Leadership': 'Lead others to achieve objectives in the most effective way; Provide an inclusive workplace that cultivates workplace relationships and teamwork, and foster the development of others.',
  'Lifelong Learning': 'Seeking out opportunities to enhance and acquire new knowledge and skills beyond one’s specific expertise or occupational areas. ',
  'Logistics Management': 'Strategically plan, organise and monitor the movement, distribution and storage of supplies and materials in a company.',
  'Management': 'Possessing the required knowledge and skills to perform executive duties that include managing projects or leading a team of people.',
  'Mathematics': 'Using mathematics to approach problem solving.',
  'Monitoring': 'Observing and recording the performance of oneself, others, and activities and taking corrective action to improve project performance when necessary.',
  'Multitasking': 'Managing multiple responsibilities simultaneously by focusing on one task while actively keeping track of others.',
  'Negotiation': 'Mediating or engaging in a situation where two or more parties attempt to reach an amicable compromise on matters of differences.',
  'Networking': 'Proactively attempt to form, develop and maintain new professional connections for the purpose of mutual benefit in their work or careers. ',
  'Objection Handling': 'Effectively addressing and alleviating the concerns of prospective customers or clients about a product or service. ',
  'Open-Mindedness': 'Willingness to proactively consider other ideas and opinions that are against one’s beliefs, plans or goals; changing one’s perspective accordingly when it is sensible to do so.',
  'Operations Analysis': 'Studying existing operational systems to identify and analyse opportunities for improvement and generate suitable designs.',
  'Operations and Control': 'Competently run the operations of equipment or machinery.',
  'Operations Management': 'Directing the overall operations process through effective and successful management of labour, productivity, quality control and/or safety measures.',
  'Opportunistic': 'Constantly being on the lookout to capitalize on opportunities to improve or add value to a business, project or oneself.',
  'Organisational Skills': 'The process of arranging tasks or resources to create the most favorable conditions to successfully achieve a goal.',
  'Patience': 'Keeping one’s emotions in check while tolerating delay or persevering through unpleasant situations. ',
  'Persuasion': 'Convincing others to change their minds or behaviour about a topic or issue. ',
  'Planning': 'Developing a series of actions to accomplish work operations and objectives; arranges and assigns work to use resources efficiently.',
  'Positive Attitude': 'Maintaining a mental outlook of optimism with an active expectation of good, even when faced with challenging or stressful situations.',
  'Presentation': 'Developing and executing clear presentations using a variety of media effectively to individuals or groups, based on their characteristics and needs. ',
  'Problem Solving': 'Identifying problem areas and generating feasible and highly-relevant solutions to address them.',
  'Professionalism': 'Displaying the conduct, behaviour and attitude that is to be expected of someone in a business environment. ',
  'Project Management': 'Applying processes, methods, skills, knowledge and experience to deliver on project goals within its specified parameters.',
  'Prospecting': 'Initiating and developing new business for a company by seeking out prospective customers or clients. ',
  'Rapport Building ': 'Establishing a positive initial connection with others that lays the foundations for meaningful business relationships.',
  'Reading Comprehension': 'The ability to understand written language, particularly content that relates to the individual’s field of work. ',
  'Report Writing': 'Documenting relevant information in a well-structured, concise, and professionally presented report ',
  'Resource Management': 'Effective and efficient allocation of the right resources to the right areas to ensure optimal utilization.',
  'Relationship Management': 'Formulating and deploying strategies to maintain and improve existing relationships with any external partners or customers.',
  'Results-Oriented ': 'The ability to focus on the desired result of one’s own or one’s unit’s work, setting challenging goals, focusing effort on the goals, and meeting or exceeding them.',
  'Role Playing': 'Communicating to others by acting out scenarios without a script to facilitate their understanding of a certain situation. ',
  'Self-Awareness': 'Awareness of one’s strengths and weaknesses and their influence on workplace performance and relationships. ',
  'Self-Motivated': 'Possessing an internal drive to achieve and commit to personal goals and new opportunities; press on in the face of adversities. ',
  'Sense-Making': 'Organising and analysing data to identify patterns or relationships that generate insights to guide decision-making.',
  'Sensitivity': 'Accurately assessing and willingly accepting another person’s thoughts, feelings and behaviours when communicating with others.',
  'Service Orientation': 'A proactive commitment to identify, meet and exceed the needs of customers',
  'Social Media Management': 'Effectively navigating the social media field with a deep understanding of what makes content shareable.',
  'Social Perceptiveness': 'Being aware of the reactions of others and understanding why they react the way they do.',
  'Stakeholder Management': 'Managing good relationships with key stakeholders by accurately identifying and understanding their power, influence, and interest.',
  'Strategic Thinking': 'Engaging in an intentional and rational thought process to position an organisation for the future by anticipating potential threats and opportunities.',
  'Strategy': '',
  'Supply Chain Management': 'Overseeing and managing the company’s overall supply chain strategy through the coordination of all activities involved in the identification, acquisition, production and distribution of the company’s goods. ',
  'Systems Evaluation': 'Identifying and analysing indicators of performance in a system and the necessary actions to improve or correct it to achieve goals of the system.',
  'Tact': 'Communicating sensitive or potentially hurtful information in a respectful and thoughtful manner without compromising on honesty. ',
  'Teamwork': 'Working collaboratively and effectively with others in an organized group to achieve common goals. ',
  'Team Management': 'Leading a group of individuals in accomplishing a task or common goal by supporting, communicating, and coordinating their tasks and actions. ',
  'Time Management': 'Managing the allocation of one’s own time and the time of others towards specific activities that produce optimal productivity.',
  'Transdisciplinary Thinking': 'Possessing the knowledge and capacity to integrate concepts across multiple disciplines to generate multi-faceted perspectives that guide decision-making and promoting cooperation within and outside of one’s organisation. ',
  'Versatility': 'Being able to effectively switch between various work tasks and adapt to the change in work situations swiftly.',
  'Virtual Collaboration': 'Collaborating effectively with team members through technological platforms on tasks or projects, in place of physical interactions and communication.',
  'Work Ethics': 'Possessing a strong set of values that guide one’s approach, behaviour and attitude towards work tasks and activities. ',
  'Work under Pressure': 'Keeping a level head and maintaining one’s ability to produce quality work when urgent, high-pressure situations arise, as opposed to being overwhelmed with emotions. ',
};
/* eslint-enable */

const RETAKE_LIMIT = 7;

const S3_CHUNK_UPLOAD_CONFIG = {
  chunkSize: 5 * 1024 * 1024, // 1MB chunk size
};
const MAX_CONCURRENT_UPLOADS = 5;
const RETRY_ATTEMPTS = 3;
const RETRY_DELAY = 1000; // 1 second

export {
  ERROR_CODES,
  INTERVIEW_STATUS,
  RECORDING_STATUS,
  APPLICATION_STATUS,
  HOSPITALITY_ROLES,
  AUTH_REDIRECT_KEY,
  LANGUAGE_KEY,
  SUPPORTED_LANGUAGES,
  PURCHASE_AMOUNT_US,
  PURCHASE_AMOUNT_INDIA,
  STRIKE_THROUGH_VALUE,
  BLURED_SCORE_IMAGE,
  WORKMAP_DEFINITIONS,
  DUMMY_REPORT_IMAGE,
  BANNER_PERSON_IMAGE,
  META_TITLE_COMPANY_HIRING,
  META_DESCRIPTION_COMPANY_HIRING,
  CLOSING_ACCOUNT_RESPONSES,
  CANDIDATE_PURCHASE_BANNER_INR,
  CANDIDATE_PURCHASE_BANNER_USD,
  CANDIDATE_MOBILE_PURCHASE_BANNER_USD,
  CANDIDATE_MOBILE_PURCHASE_BANNER_INR,
  CANDIDATE_OVERVIEW_BANNER_INR,
  CANDIDATE_OVERVIEW_BANNER_USD,
  RETAKE_LIMIT,
  S3_CHUNK_UPLOAD_CONFIG,
  MAX_CONCURRENT_UPLOADS,
  RETRY_ATTEMPTS,
  RETRY_DELAY,
};
