export default {
  helloApplicant: 'Halo Pelamar,',
  thankYou: 'Terima kasih.',
  question: 'Pertanyaan',
  start: 'Mulai',
  submitting: 'Mengirimkan',
  submit: 'Kirim',
  sending: 'Mengirim',
  proceed: 'Lanjutkan',
  close: 'Tutup',
  poweredBy: 'Didukung oleh',
  contactUs: 'Hubungi Kami',
  showMore: 'Tampilkan Lebih Banyak',
  showLess: 'Tampilkan Lebih Sedikit',
  applying: 'Mengajukan Lamaran',
  apply: 'Ajukan Lamaran',
  click: 'Klik',
  done: 'Selesai',
  uploading: 'Mengunggah',
  cantFindPage: 'Kami tak dapat menemukan halaman yang Anda cari.',
  usefulLinks: 'Berikut ini beberapa tautan yang mungkin berguna',
  menuHome: 'Beranda',
  menuAboutUs: 'Tentang Kami',
  menuTnC: 'Syarat dan Ketentuan',
  menuPrivacy: 'Privasi',
  tnc1: 'Dengan mengajukan lamaran, Anda setuju dengan ',
  tnc2: 'Ketentuan Penggunaan Kandidat Interviewer.AI',
  amInterested: 'Saya tertarik',
  second: 'detik',
  second_plural: 'detik',
  secondWithCount: '{{count}} detik',
  secondWithCount_plural: '{{count}} detik',
  menit: 'menit',
  minute_plural: 'menit',
  minuteWithCount: '{{count}} menit',
  minuteWithCount_plural: '{{count}} menit',
  workmapScreen: {
    start: 'Mulai',
    goBack: 'Kembali',
    skillsAssessment: 'Ceritakan Tentang Diri Anda',
    of: 'Dari',
    answers: 'Jawaban',
    questions: 'pertanyaan',
    submit: 'Kirim',
    greeting: 'Hi {{name}},',
    unansweredQuestions: 'Pertanyaan belum terjawab:',
    description: 'Selamat menjalani tahap asesmen AI Interview!',
    instruction: '',
    instruction1: 'Harap menanggapi setiap pernyataan berdasarkan apa yang paling banyak Anda lakukan di tempat kerja.',
    instruction2: 'Saat menanggapi pernyataan, pilih opsi yang mencerminkan cara Anda biasanya berpikir atau merasakan.',
    instruction3: 'Tidak ada jawaban benar atau salah dalam asesmen ini, jadi tak perlu menghabiskan terlalu banyak waktu untuk satu jawaban.',
    instruction4: 'Bersikaplah spontan dan janganlah menjawab berdasarkan anggapan Anda tentang jawaban yang ingin kami dengar.',
    instruction5: 'Jujurlah dalam jawaban Anda. Tanggapan Anda akan mempengaruhi keakuratan penilaian Anda.',
    instruction6: 'Menyelesaikan penilaian ini akan memakan waktu sekitar 5 menit. Klik pada tombol "Mulai" kapan saja Anda siap. Semoga sukses!',
    instruction7: 'Anda masih memiliki pertanyaan yang belum terjawab. Harap selesaikan semua pertanyaan sebelum mengirimkan.',
    stronglyDisagree: 'Sangat Tidak Setuju',
    disagree: 'Tidak Setuju',
    somewhatDisagree: 'Agak Tidak Setuju',
    neitherAgreeNorDisagree: 'Netral',
    somewhatAgree: 'Agak Setuju',
    agree: 'Setuju',
    stronglyAgree: 'Sangat Setuju',
    aiaInstruction1: 'Saat ini, Anda berada di tahap pengisian kuesioner profil.',
    aiaInstruction2: 'Gunakan kesempatan ini untuk menceritakan tentang diri Anda agar kami dapat menilai kecocokan Anda dengan persona {{jobName}} {{aiaCompanyName}} yang diharapkan.',
    aiaInstruction3: 'Silakan luangkan waktu Anda sekitar 5-10 menit untuk menyelesaikan asesmen ini dan klik “Mulai” ketika Anda sudah siap.',
  },
  scale: {
    'Not at all': 'Tidak sama sekali',
    Rarely: 'Jarang',
    Sometimes: 'Terkadang',
    Often: 'Sering',
    'Very Often': 'Sangat Sering',
    'Strongly Disagree': 'Sangat Tidak Setuju',
    'Somewhat Disagree': 'Agak Tidak Setuju',
    Disagree: 'Tidak Setuju',
    'Neither Agree nor Disagree': 'Bukan Setuju maupun Tidak Setuju',
    Neutral: 'Netral',
    Agree: 'Setuju',
    'Somewhat Agree': 'Agak Setuju',
    'Strongly Agree': 'Sangat Setuju',
  },
  applySendSuccessful: {
    thankyou: 'TERIMA KASIH',
    instruction: 'Kami telah mengirim email yang berisi tautan halaman wawancara. Kami menyarankan Anda untuk melakukan rekaman dalam 48 jam ke depan untuk memaksimalkan peluang Anda untuk diundang ke tahap berikutnya. Semoga beruntung!',
    instruction1: 'Hasil wawancara Anda sudah kami terima.',
    instruction2: 'Kami akan menghubungi Anda melalui email untuk proses selanjutnya.',
    instruction3: 'Semoga sukses selalu!',
  },
  applySuccessful: {
    congrats: 'Selamat {{name}}!',
    submitted: 'Terima kasih atas tanggapan Anda!',
    whatsNext: '',
    instruction: 'Selanjutnya, Anda akan memulai tahap rekaman video wawancara. Gunakan kesempatan ini untuk menunjukan sisi terbaik dan kelebihan Anda.',
    coachingInstruction: 'Langkah selanjutnya dalam proses pengajuan lamaran Anda adalah untuk merekam video menjawab pertanyaan-pertanyaan asesmen.',
    instruction1: '1. Pastikan Anda berada di tempat yang tenang dengan pencahayaan yang cukup terang',
    instruction2: '2. Koneksi internet stabil',
    instruction3: '3. Persiapkan sikap yang percaya diri dan pengucapan yang lantang agar suara Anda terdengar jelas',
    instruction4: '',
    instruction5: '',
    giveBestShot: '',
    helpfulTips: 'Tips sebelum merekam wawancara:',
    doWithin48: 'Lakukan dalam waktu 48 jam - kirim tautan kepada saya',
    wsgClientInstruction1:
      '5. Harap diketahui bahwa WSG akan menyaring semua video yang direkam sebelum dikirimkan ke pemberi kerja. Video yang berisi atau menampilkan perilaku yang tidak sesuai atau tidak profesional seperti penggunaan kata-kata yang menyinggung atau latar belakang yang tidak pantas tidak akan diserahkan kepada pemberi kerja untuk pemrosesan lamaran kerja. Pelamar kerja akan diberi tahu untuk merevisi rekaman video dan mengirimkannya kembali.',
    doNow: 'Mulai',
    infoProvide: 'Terima kasih atas informasi yang diberikan',
    opportunityText: 'Ini adalah kesempatan Anda untuk menunjukkan kepribadian, semangat, dan energi Anda, jadi berikan yang terbaik!',
    quietSpot: 'Tempat yang tenang dan pastikan',
    strongNetwork: 'Jaringan yang kuat',
    confidentLoud: 'Percaya diri dan cukup keras',
    videoCompleted: 'Video dapat diselesaikan',
  },
  applySuccessfulDelayInterview: {
    thankyou: 'Terima kasih {{name}}!',
    submitted: 'Lamaran Anda telah diajukan.',
    instruction: 'Tim kami akan meninjau lamaran Anda dan akan segera menghubungi Anda apabila Anda terpilih untuk tahap berikutnya. Harap diketahui bahwa hanya kandidat terpilih yang akan diberi tahu.',
    goodLuck: 'Semoga Anda beruntung!',
  },
  candidateProfile: {
    intro: 'Perkenalkan diri Anda',
    instruction: 'Sebelum merekam wawancara video, kami meminta Anda meluangkan waktu untuk memperkenalkan diri.',
    placeholder: 'Ceritakan tentang diri Anda',
  },
  browser: {
    worksBest: 'Interviewer.AI berfungsi paling baik saat ini menggunakan Chrome dan Firefox.',
    bestExperience: 'Kami bekerja keras untuk memastikan setiap orang memiliki pengalaman terbaik dalam wawancara!',
    meantime: 'Sementara itu, instal versi terbaru Google Chrome atau Mozilla Firefox dan akses tautan wawancara yang sama untuk wawancara Anda:',
    downloadChrome: 'Unduh Chrome',
    downloadFirefox: 'Unduh Firefox',
    lookForward: 'Kami menantikan tanggapan Anda!',
    bestRegards: 'Salam,',
    interviewerTeam: 'Tim Interviewer.AI',
    scanQrCode: 'Pindai kode QR untuk merekam di ponsel',
  },
  digitalProfile: {
    interviews: 'Wawancara',
    interviewName: 'Nama Wawancara',
    status: 'Status',
    dateResponded: 'Tanggal Jawaban',
    ringkasan: 'Ringkasan',
    topSkills: 'Keterampilan Utama',
    viewResume: 'Lihat Resume',
    visitLinkedin: 'Kunjungi Linkedin',
    myWorkmapScore: 'Skor Workmap Saya',
  },
  organisationIntroVideoScreen: {
    continue: 'Lanjut',
    note: '(Catatan : Tombol Lanjutkan akan diaktifkan setelah menonton video lengkap)',
  },
  endedScreen: {
    recordAgain: 'Rekam Lagi',
    recordAgainQuestion: 'Apakah Anda yakin ingin merekam lagi?',
    recordAgainDescription: 'Harap diingat bahwa Anda harus melakukan ulang seluruh wawancara dari pertanyaan 1. Tekan tombol Rekam lagi untuk memulai ulang, atau Anda dapat menutup jendela ini dan melanjutkan untuk mengirimkan tanggapan Anda.',
    thankyou: 'Terima kasih {{name}}! Anda telah menyelesaikan wawancara.',
    coachingThankyou: 'Terima kasih {{name}}! Anda telah menyelesaikan asesmen.',
    instruction1: 'Anda dapat meninjau jawaban Anda di atas sebelum',
    instruction2: 'mengirimkannya dengan tombol di bagian bawah halaman.',
    getFeedback: 'Untuk membantu kami meningkatkan produk ini, kami sangat menghargai umpan balik Anda di bawah ini! Apa pendapat Anda tentang pengalaman rekaman tersebut?',
    feedback: 'Umpan balik',
    placeholder: 'Tanggapan Anda sangat dihargai!',
    rateExp: 'Nilai pengalaman wawancara Anda',
    Hi: 'Hi!',
    checkVideo: 'Yuk cek video wawancara Anda!',
    submit: 'Kirim semua jawaban',
  },
  thankyouEndScreen: {
    radioText1: 'Tidak tertarik dengan Pekerjaan itu',
    radioText2: ' Tidak tertarik dengan wawancara Video',
    radioText3: 'Akan melakukannya nanti',
    radioText4: 'Tidak dapat mengirimkan',
    radioText5: ' Yang lain',
    pleaseChooseReason: 'Silakan pilih alasannya',
    stateYourReason: 'Silakan sebutkan alasan Anda',
    notFinished: 'Anda belum menyelesaikan wawancara.',
    notSubmittedVideoResp: 'Tanggapan video Anda belum dikirimkan.',
    noSubmittion: 'Tidak Ada Penyerahan!',
    shareYourFeedback: 'Silakan bagikan tanggapan Anda.',
    tellUsQuittingInterview: 'Beri tahu kami jika Anda berhenti dari wawancara atau menghadapi tantangan apa pun.',
    ratingStarsMandatory: 'Penilaian bintang adalah wajib',
    pleaseDontLeaveField: 'Mohon jangan biarkan kolom ini kosong',
    congratulations: 'Selamat!',
    responseSent: 'Kami telah menerima video Anda.',
    instruction1: 'Tunggu hingga Anda mendapatkan pesan pengiriman yang berhasil karena aplikasi Anda mungkin sedang dalam proses',
    instruction2: 'Kami melakukan yang terbaik untuk mengevaluasi tanggapan Anda dalam hitungan menit. Namun mungkin memakan waktu antara 30 menit sampai 2 jam. Mohon tunggu beberapa saat untuk diproses.',
    instruction3: 'Anda akan menerima email setelah dievaluasi. Periksa folder spam Anda jika Anda tidak mendapatkan email dalam waktu 2 jam.',
    instruction4: 'Anda dapat menghubungi kami di support@interviewer.ai untuk setiap pertanyaan tambahan. Selamat mewawancarai!',
    finishedInterview: 'Anda telah menyelesaikan wawancara.',
    shareExperience: 'Bagikan pengalaman merekam Anda.',
    submit: 'Kirim',
    thankyou: 'Terima kasih',
    feedbackText: 'Untuk membantu kami meningkatkan produk kami, kami menghargai umpan balik Anda di bawah ini! apa pendapat Anda tentang pengalaman rekaman?',
    feedback: 'Masukan',
  },
  interviewCompleted: {
    submitted: 'Anda telah mengirimkan tanggapan untuk wawancara',
    thankyou: 'Terima kasih atas tanggapan Anda! Jika ada pertanyaan, jangan ragu untuk menghubungi kami:)',
  },
  interviewFailure: {
    errorMessage: 'Kami melihat bahwa tautan yang Anda akses tidak valid atau tidak ada. Jika Anda menerima tautan ini dari pemberi kerja, silakan hubungi pemberi kerja secara langsung untuk mendapat tautan baru.',
    title: 'Ups, tautan wawancara tampaknya tidak valid!',
    contactUsBody: 'Tidak dapat mengakses tautan wawancara video&body=Hai, saya tidak dapat mengakses tautan wawancara: {{- link }}. Dinyatakan bahwa tautan wawancara tampaknya ditutup oleh pemberi kerja. Bisakah Anda memeriksanya?',
  },
  interviewSignUp: {
    name: 'Nama',
    namePlaceholder: 'Nama Lengkap',
    email: 'Email',
    emailPlaceholder: 'email_Anda@contoh.com',
    school: 'Sekolah',
    telepon: 'Telepon',
    phone: 'Telepon',
    required: 'Wajib Diisi',
    applicationForm: 'Formulir Aplikasi',
    companyName: 'Perusahaan',
    roleOverview: 'Ikhtisar Peran',
    employmentType: 'Jenis Pekerjaan',
    seniorityLevel: 'Tingkat Senioritas',
    experience: 'Pengalaman',
    submit: 'Kirimkan',
    apply: 'Aktif',
    linkedin: 'Profil LinkedIn',
    resume: 'Melanjutkan (hanya pdf)',
    errorExpired: 'Ups, tautan wawancara tampaknya lewat batas waktu!',
    errorMessage: 'Kami melihat bahwa tautan yang Anda akses tidak lagi tersedia. Wawancara telah berakhir dan belum diaktifkan kembali oleh pemberi kerja. Jika Anda merasa, lowongan masih aktif atau pemberi kerja masih mencari karyawan, silakan hubungi pemberi kerja secara langsung.',
    contactUsBody: 'Tidak dapat mengakses tautan wawancara video&body=Hai, saya tidak dapat mengakses tautan wawancara: {{- link }}. Dikatakan bahwa tautan wawancara tidak lagi dapat diakses. Bisakah Anda memeriksanya?',
    toApplyFor: 'Untuk melamar',
    coachingToApplyFor: 'Untuk melanjutkan asesmen Anda, harap kirimkan detail di bawah ini:',
    submitInfo: ', harap kirimkan informasi Anda di bawah ini:',
    selectFile: 'Pilih File',
    supportPDF: 'Mendukung file PDF saja',
    dragPDF: 'Seret pdf Anda ke sini atau klik untuk',
    browse: 'jelajahi',
    your: 'milikmu',
    IP: {
      programOfStudy: 'Program Studi',
      school: 'Sekolah',
      formTitle: 'Pendaftaran Wawancara Video Daring Tiruan InsightPro',
      instruction1: '1. Setelah menyelesaikan pendaftaran, Anda dapat memilih untuk segera memulai wawancara video atau menyelesaikannya nanti melalui tautan yang disediakan melalui email.',
      instruction2: '2. Wawancara mencakup 8 pertanyaan perilaku dan akan berlangsung sekitar 15 menit.',
      instruction3: '3. Laporan Asesmen wawancara Anda akan dikirimkan ke email yang terdaftar dalam waktu 3 hari kerja setelah selesainya wawancara.',
      instruction4: '* Harap diketahui bahwa wawancara yang tidak lengkap atau tidak valid tidak akan ditinjau dan oleh karenanya tidak ada hasil asesmen.',
      toApplyFor: 'Untuk melamar',
      at: 'pada',
      howYouHear: 'Dari mana Anda mendengar tentang acara ini?',
      indicateIndustry: '(Harap memberi tahu pada industri dan jabatan apa yang ingin Anda tuju.)',
      yearOfGrad: 'Tahun Kelulusan',
      careerInterests: 'Minat Karier',
      submitInfo: ', silakan kirim informasi Anda di bawah ini:',
      linkedin: 'Media Sosial - LinkedIn',
      wechat: 'Media Sosial - WeChat',
      friend: 'Teman',
      infoBooth: 'Kios Informasi',
      searchEngine: 'Mesin Pencari',
    },
  },
  interviewSignUpSideScreen: {
    processHeading: '3 Langkah Proses',
    fillApplication: 'Isi Aplikasi',
    completeAssessment: 'Penilaian Lengkap',
    videoResponse: 'Rekam Tanggapan Video',
  },
  multiAttempts: {
    title: 'Oh tidak, tautan ini hanya berlaku untuk satu kali akses!',
    errorMessage: 'Kami melihat bahwa tautan yang Anda akses tidak lagi tersedia. Ini adalah tautan satu kali akses, jika Anda tidak dapat mengirimkan jawaban dalam upaya sebelumnya. Silakan hubungi pemberi kerja secara langsung untuk mendapatkan tautan baru atau untuk mengonfirmasi penerimaan tanggapan awal Anda.',
    contactUsBody: 'Tautan wawancara hanya berlaku untuk akses satu kali&body=Hai, saya tidak dapat mengakses tautan wawancara: {{- link }}. Dikatakan bahwa tautan tersebut hanya berlaku untuk satu kali akses. Bisakah Anda memeriksanya?',
  },
  preQuestionReview: {
    title: 'Ini adalah rekaman pertanyaan tes',
    pleaseNote: 'Harap diketahui:',
    description1: 'Meskipun video ini tidak akan dikirimkan, seperti inilah tampilan video Anda ',
    description2: '. Pastikan wajah Anda terlihat di depan kamera dan suara Anda jernih. ',
    employer: 'pemberi pekerjaan',
    warning: 'CATATAN: Setelah Anda memulai wawancara, Anda tidak akan dapat kembali atau memulai ulang.',
    coachingWarning: 'CATATAN: Setelah Anda memulai asesmen, Anda tidak akan dapat kembali atau memulai ulang.',
    instruction1: 'Ketika Anda memulai wawancara, Anda hanya memiliki',
    coachingInstruction1: 'Ketika Anda memulai asesmen, Anda hanya memiliki',
    instruction2: 'untuk membaca pertanyaan dan mempersiapkan jawaban Anda. Semoga beruntung!',
    prepTime: '{{prepTime}} detik',
    troubleShootRedirection: 'Tautan dukungan untuk membantu dan memecahkan masalah kamera/mikrofon',
    clickLink: 'klik di sini',
  },
  interview: {
    testAgain: 'Tes Lagi',
    startInterview: 'Mulai Wawancara',
    recordResponse: 'Rekam Jawaban',
    goodLuck: 'Semoga berhasil!',
    interview: 'nilai',
    testQuestion: 'Ini adalah soal ujian',
    '10seconds': ' Anda memiliki 10 detik untuk menjawab pertanyaan ini.',
    allowDevices: 'Anda harus mengizinkan browser mengakses kamera dan mikrofon.',
    done: 'Selesai',
    preparation: 'Persiapan',
    remainingTime: 'Waktu Tersisa',
    back: 'Kembali',
    checkYourVideo: 'Periksa video Anda',
    okayLetsProceed: 'Oke, mari kita lanjutkan',
    startPractice: 'Latihan dulu yuk!',
    importantNotes: '',
    instruction1: 'Anda akan diberikan 1 pertanyaan latihan.',
    instruction2: 'Tenang saja, ini tidak akan mempengaruhi penilaian',
    instruction3: 'Klik ‘Mulai’ jika sudah siap.',
    startTest: 'Mulai',
    alreadyApplied: 'Sudah diterapkan!',
    alreadyAppliedToTheJob: 'Anda telah melamar pekerjaan ini menggunakan',
    clickBelowToProceed: 'Klik di bawah untuk melanjutkan.',
    continue: 'Melanjutkan',
    blockerHeading: 'Anda perlu mengaktifkan izin mikrofon dan video untuk melanjutkan.',
    blockerPrimaryText: 'Untuk mengaktifkan izin mikrofon dan video, ikuti langkah-langkah berikut.',
    forChrome: 'Untuk Chrome:',
    chromeBlockerStep1: '1) Di kanan atas, klik Lainnya. Pengaturan.',
    chromeBlockerStep2: '2) Klik Privasi dan keamanan Pengaturan situs. Kamera atau Mikrofon.',
    forFireFox: 'Untuk Firefox:',
    firefoxBlockerStep1: '1) Klik tombol menu dan pilih Pengaturan.',
    firefoxBlockerStep2: '2) Klik Privasi & Keamanan dari menu sebelah kiri.',
    firefoxBlockerStep3: '3) Gulir ke bawah ke bagian Izin.',
    firefoxBlockerStep4: '4) Klik tombol Pengaturan untuk opsi Mikrofon dan video',
    questions: 'Pertanyaan',
  },
  preQuestion: {
    qtitle: 'Apa warna favorit Anda dan mengapa?',
    title1: 'Ini adalah',
    title2: 'pertanyaan untuk latihan',
    title3: 'untuk menguji video dan audio Anda.',
    notSubmitted: 'Ini tidak akan ditambahkan ke pengajuan Anda.',
    toTest: 'untuk menguji',
    instruction1: 'Setelah Anda merasa nyaman dengan pengalaman merekam, klik',
    instruction2: 'di bawah untuk melanjutkan.',
    warning: 'CATATAN: Anda hanya dapat melakukan wawancara ini 1 kali. Silakan gunakan pertanyaan ini untuk menguji suara dan video Anda sebelum Anda memulai wawancara yang sebenarnya.',
    '10seconds': ' Anda memiliki 10 detik untuk menjawab pertanyaan ini.',
    allowDevices: 'Anda harus mengizinkan browser mengakses kamera dan mikrofon.',
    numOfQuestions: 'Total ada {{numberOfQuestions}} pertanyaan dalam wawancara ini.',
    troubleShootRedirection: 'Tautan dukungan untuk membantu dan memecahkan masalah kamera/mikrofon',
    clickLink: 'klik di sini',
  },
  recording: {
    howManySeconds: 'Anda memiliki {{seconds}} detik untuk menjawab pertanyaan ini.',
    recordingStatus: 'Merekam',
    preparationStatus: 'Persiapan',
  },
  submitFailure: {
    ohNo: 'Oh tidak!',
    description: 'Kami mengalami beberapa kegagalan dalam mengirimkan tanggapan Anda. Ini biasanya karena koneksi jaringan terputus-putus yang menyebabkan pengiriman terkendala. Mohon maaf atas ketidaknyamanannya.',
    instruction1: 'Sayangnya, kami tidak berhasil menangkap rekaman jawaban Anda.',
    instruction2: 'Harap luangkan waktu sejenak untuk mencatat ulang tanggapan Anda atas pertanyaan-pertanyaan ini.',
    instruction3: 'Jika Anda terus menghadapi tantangan setelah mengirimkan ulang, jangan ragu untuk menghubungi kami di ',
    instruction4: 'Anda juga dapat merekam ulang jawaban Anda untuk pertanyaan-pertanyaan ini di perangkat lain dengan memindai kode qr di bawah.',
    recordAgain: 'Rekam Lagi',
    networkStatus: 'Status jaringan',
    connected: 'Terhubung',
    notConnected: 'Tidak terhubung',
    check1: 'Periksa apakah Anda ',
    check2: 'terhubung ke internet di sini.',
    reSubmit: 'Kirim ulang',
  },
  submittedScreen: {
    title: 'Tanggapan Anda telah dikirimkan!',
    thankyou: 'Terima kasih atas waktu Anda yang berharga untuk wawancara dengan kami, kami harap Anda menikmati pengalaman ini!',
    redirect1: 'Anda sekarang akan dialihkan dalam {{time}} detik...',
    orClick: 'Atau klik ',
    here: 'di sini',
    ifNotRedirected: 'jika Anda tidak dialihkan',
  },
  submittingScreen: {
    title: 'Kami sedang mengirimkan tanggapan Anda, jangan menutup halaman Anda...',
  },
  landing: {
    dear: '{{name}} yang terhormat,',
    hi: 'Hai {{name}},',
    previous: 'Sebelumnya',
    next: 'Berikutnya',
    description: 'Selangkah lagi untuk bergabung menjadi',
    coachingDescription: 'Sebagai bagian dari asesmen daring, Anda diminta untuk merekam jawaban video terhadap pertanyaan asesmen',
    with: 'dengan ',
    at: 'pada',
    quietSpot: 'Cari tempat yang tenang dengan pencahayaan yang cukup dan WiFi yang lancar untuk menjawab asesmen Anda.',
    instructions: 'Instruksi',
    instruction1: 'Asesmen video ini terdiri dari',
    instruction1a: 'pertanyaan dan akan membawa Anda',
    instruction1b: 'untuk menyelesaikan, tergantung pada jumlah pertanyaan yang diberikan kepada Anda.',
    instruction2: 'Anda diminta untuk menyelesaikan asesmen dalam satu kali pengambilan.',
    instruction3: 'Setelah disajikan pertanyaan pertama, pewaktu otomatis dimulai. Anda akan diberikan waktu persiapan ',
    instruction3a: ', diikuti dengan batas waktu perekaman yang bisa berbeda untuk masing-masing pertanyaan.',
    instruction4: 'Ingatlah untuk memperhatikan pewaktu sambil mempersiapkan atau merekam jawaban Anda. Jika Anda telah siap sebelum batas waktu, Anda dapat memilih untuk mulai merekam jawaban Anda atau mengirimkannya lebih awal.',
    coachinginstruction5: 'Harap diperhatikan: Jangan menyegarkan, menutup, atau keluar dari halaman setelah asesmen dimulai, karena Anda tidak akan diizinkan untuk mengakses tautan asesmen lagi.',
    instruction5: 'Harap diperhatikan: Jangan menyegarkan, menutup atau keluar dari halaman setelah wawancara dimulai, karena Anda tidak akan diizinkan untuk mengakses tautan wawancara lagi.',
    instruction6: 'Jangan khawatir, Anda akan dapat meninjau tanggapan video Anda di akhir seluruh asesmen. Jika Anda merasa bahwa jawaban Anda tidak memuaskan, Anda memiliki pilihan untuk merekam ulang seluruh asesmen',
    instructionRedo: '* Namun, urutan dan sifat pertanyaan dapat berubah untuk memastikan Anda tampil alami dan spontan selama wawancara video.',
    envCheck: 'Pemeriksaan Peralatan/Lingkungan',
    env1: 'Cari tempat yang tenang dengan pencahayaan yang cukup dan WiFi yang lancar untuk merekam asesmen Anda.',
    env2: 'Perekaman Interviewer.AI saat ini hanya berfungsi di Chrome dan Firefox. Pastikan bahwa Anda menggunakan salah satu halaman ini untuk merekam asesmen Anda.',
    env3: 'Untuk melanjutkan wawancara video, kami memerlukan akses ke kamera video dan mikrofon Anda.',
    coachingEnv3: 'Untuk melanjutkan asesmen video, kami memerlukan akses ke kamera video dan mikrofon Anda.',
    env4: 'Anda akan diperlihatkan pertanyaan untuk menguji kualitas audio dan video Anda sebelum memulai asesmen yang sebenarnya.',
    env5: 'Pastikan untuk memposisikan diri Anda dengan baik dalam bingkai layar Anda, duduk tegak dan memandang langsung ke kamera ketika memberikan jawaban Anda.',
    env6: 'Anda akan dapat meninjau jawaban video Anda di akhir seluruh asesmen. Jika Anda merasa bahwa jawaban Anda tidak memuaskan, Anda memiliki pilihan untuk merekam kembali seluruh asesmen.',
    readyClick: 'Klik pada tombol "Lanjutkan" kapan saja Anda siap. Semoga sukses!',
    resumeOnly: 'Resume (hanya pdf)*',
    error: 'Silakan unggah hanya file pdf. File ditolak',
    aia_instruction1: 'Di tahap ini, kami ingin mendengar lebih banyak cerita tentang Anda. Caranya mudah, Anda cukup merekam video wawancara dengan durasi berikut:',
    aia_instruction2: '45 detik untuk persiapan',
    aia_trial: '(trial)',
    aia_instruction3: '1 menit untuk menjawab 1 pertanyaan (total 3 pertanyaan)',
    aia_instruction4: 'Anda bisa mencoba tips berikut untuk menghindari kendala teknis:',
    aia_instruction5: 'Gunakan browser Chrome atau Firefox',
    aia_instruction6: 'Berikan akses ke kamera video dan mikrofon',
    aia_instruction7: 'Setelah selesai merekam video, Anda dapat melihat ulang hasil rekaman. Apabila belum puas, Anda dapat mengulang proses rekaman video satu kali lagi.',
    aia_instruction8: 'Semoga sukses!',
    env7: 'Jangan beralih atau beralih antar browser saat merekam.',
    env8: 'Pertahankan kontak mata Anda dan jangan terlalu mengalihkan perhatian Anda saat mencatat respons Anda.',
    env9: 'Audio diambil saat Anda merekam. Pastikan Anda adalah satu-satunya orang yang berbicara selama rekaman wawancara.',
    instruction7: 'Silakan unggah dokumen identifikasi yang benar seperti yang diamanatkan (Kartu Identitas Pemerintah, dll.)',
  },
  resumeParser: {
    title: 'Demo Pengurai Resume',
    description: 'Unggah satu resume di bawah ini untuk melihat bagaimana sistem kami mengurai dan mengevaluasi resume kandidat',
    dragResume: 'Seret dan taruh resume di sini, atau klik untuk memilih file (hanya pdf)',
    parsedResults: 'Hasil penguraian',
    experience: 'Pengalaman',
    personalDetails: 'Rincian Pribadi',
    totalYoE: 'Total Tahun Pengalaman',
    company: 'Perusahaan',
    position: 'Jabatan',
    duration: 'Durasi',
    skills: 'Keterampilan',
    education: 'Pendidikan',
    institute: 'Institut',
    degree: 'Gelar',
    mayor: 'Jurusan',
    score: 'Skor',
    overall: 'Secara keseluruhan',
    betterFitNote: 'Catatan: kandidat dengan skor resume keseluruhan 55-100 lebih cocok berdasarkan Deskripsi dan Persyaratan Pekerjaan yang ditetapkan.',
    name: 'Nama',
    email: 'Email',
    telepon: 'Telepon',
  },
  countdown: {
    recordIn: 'Merekam dalam',
  },
  prepTimer: {
    timer: 'Waktu persiapan',
  },
  candidateDetails: {
    back: 'Kembali',
    download: 'Unduh',
    questions: 'Pertanyaan',
    videoTranscript: 'Transkrip Video',
    resumeScore: 'Skor Resume',
    workmapScore: 'Skor Workmap',
    aiVideoScore: 'Skor Video AI',
    videoAssessment: 'Asesmen Wawancara Video',
    text: 'Teks',
    voice: 'Suara',
    facialExpression: 'Ekspresi Wajah',
    profesionalisme: 'Profesionalisme',
    energyLevel: 'Tingkat Energi',
    communication: 'Komunikasi',
    sociability: 'Kemampuan Sosial',
    pace: 'Kecepatan',
    charity: 'Kejelasan',
    sentiments: 'Sentimen',
    energi: 'Energi',
    positivity: 'Kepositifan',
    positiveEmotion: 'Emosi Positif',
    aboutThisAssessmentReport: 'Tentang Laporan Penilaian Ini',
    introduction: 'Pendahuluan',
    useOfReport: 'Penggunaan Laporan',
    confidentialityDisclaimer: 'Kerahasiaan & Penafian',
    workmapAssessment: 'Asesmen Workmap',
    actualScore: 'Skor Aktual',
    resumeScoring: 'Penilaian Resume',
    excellent: 'Sangat Baik',
    high: 'Tinggi',
    medium: 'Sedang',
    low: 'Rendah',
    glossaryIndex: 'Glosarium/Indeks',
    definitionOfVideoInterviewParameter: 'Definisi parameter Wawancara Video',
    candidateSummary: 'Ringkasan Kandidat',
    notAvailable: 'Tidak Tersedia',

  },
  kesalahan: {
    '404_CANDIDATE_DATA_NOT_FOUND': 'Silakan lengkapi wawancara video Anda untuk melihat penilaian.',
    '404_ASSESSMENT_DATA_NOT_READY': 'Wawancara video Anda saat ini sedang dinilai. Silakan kembali lagi segera!',
    '404_CANDIDATE_NOT_AVAILABLE': 'Anda belum melakukan wawancara video. Silakan kembali setelah Anda mengirimkan jawaban wawancara video Anda.',
    default: 'Telah terjadi kesalahan. Silakan mencoba lagi nanti.',
  },
  dashboardPanel: {
    dashboard: 'Dasbor',
    hey: 'Hei',
    morningGreeting: 'Selamat Pagi',
    afternoonGreeting: 'Selamat Siang',
    eveningGreeting: 'Selamat Malam',
    upgrade: 'Tingkatkan',
    support: 'Dukungan',
    signOut: 'Keluar',
    collapse: 'Ciutkan',
    faqs: 'T&J',
    company: 'Perusahaan',
    role: 'Jabatan',
    status: 'Status',
    action: 'Tindakan',
    date: 'Tanggal',
    recordNow: 'Rekam Sekarang',
    responded: 'Telah Menjawab',
    processing: 'Memroses',
    viewDetails: 'Lihat Rincian',
    personalInfo: 'Info Pribadi',
    introVideo: 'Video Perkenalan',
    score: 'Skor',
    email: 'Email',
    telepon: 'Telepon',
    ringkasan: 'Ringkasan',
    topSkill: 'Keterampilan Utama',
    workmapScore: 'Skor Workmap',
    aiVideoScore: 'Skor Video AI',
    introText1: 'Bersiaplah untuk wawasan yang menarik. Mulailah dengan merekam',
    introText2: 'video perkenalan Anda.',
    recordVideo: 'Rekam Video',
  },
  purchaseBanner: {
    heading: 'Tingkatkan untuk melihat laporan terperinci wawancara AI Anda',
    subHeading: 'Dapatkan rasa percaya diri dalam wawancara-wawancara berikutnya .',
    launchAt: 'Penawaran peluncuran pada',
    only: 'Hanya',
    year: 'tahun',
  },
  companyDetails: {
    company: 'Perusahaan',
    about: 'Tentang',
    experience: 'Pengalaman',
    apply: 'Berlaku',
    description: 'Keterangan',
    viewJobDetailText: 'Lihat Detail Pekerjaan',
  },
  additionalFormField: {
    chooseFileBtn: 'Pilih File',
    pleaseChooseAFileText: 'Silakan pilih file',
  },
  quitInterviewModal: {
    confirmQuit: 'Konfirmasikan Keluar dari Wawancara',
    areYouSure: 'Apakah Anda yakin ingin keluar dari wawancara?',
    confirm: 'Mengonfirmasi',
    cancel: 'Membatalkan',
  },
  processingVideo: {
    instruction1: 'Memproses video Anda...',
    instruction2: 'Harap tunggu',
    instruction3: 'Hanya butuh beberapa detik...',
    instruction4: 'Bersabarlah.',
    noAudioFound: 'Audio tidak ditemukan, harap rekam ulang dengan Audio yang jernih. Silakan periksa koneksi mikrofon Anda.',
  },
  audioError: {
    message: 'Kami menyesal terjadi kesalahan saat menyalin video Anda. Silakan periksa audio Anda dan coba lagi.',
  },
};
